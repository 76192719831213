<template> <!-- role="switch" :aria-checked="!showAll"  -->
  <button
    :role="(toggle?'switch':'button')"
    :aria-checked="(toggle?checked:'')"
    :class="[
      'Button',
      type,
      (variant!=''?`variant-${variant}`:''),
      (direction==''?'direction-'+signal.direction:'direction-'+direction),
      (size==''?'size-'+signal.size:'size-'+size),
      (disabled?'disabled':'')
    ]"
    ref="button"
    :type="htype"
    :disabled="disabled">
    <div :class="['Button_Inner', `_justify-${justify}`]">
      <Icon v-if="toggle" class="Button_Inner_Icon" type="solid" :icon="(checked?'toggle-on':'toggle-off')"/>
      <img v-if="type=='ml'" class="Button_Inner_Icon" :src="require('@/assets/images/sparkle-white.svg')" alt="" />
      <Icon v-if="icon.length&&(typeof icon[2]=='undefined'||icon[2]!='after')" class="Button_Inner_Icon" :type="icon[0]" :icon="icon[1]"/>
      <span class="Button_Inner_Text" v-if="loading">Loading...</span>
      <span class="Button_Inner_Text" v-else><slot></slot></span>
      <Icon v-if="icon.length&&(typeof icon[2]!='undefined')&&icon[2]=='after'" class="Button_Inner_IconAfter" :type="icon[0]" :icon="icon[1]"/>
      <span v-if="pill!=''" class="Button_Inner_Pill"><Pill :text="pill" size="small" /></span>
    </div>
  </button>
</template>

<script>
import UIIcon from '@/components/UI/Icon';
import Pill from '@/components/UI/Pill';

export default {
  name: 'UIButton',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    // doClick: function(e) {
    //   this.$emit('click', e);
    // },
    setLoading( b ) {
      this.loading = b;
    },
    focus() {
      this.$refs.button.focus();
    },
  },
  props: {
    justify: {
      type: String,
      default: 'left',
    },
    type: {
      type: String,
      default: 'primary',
    },
    variant: {
      type: String,
      default: '',
    },
    icon: {
      type: Array,
      default: () => {
        return [];
      },
    },
    signal: {
      type: Object,
      default: () => {
        return {
          direction: 'horizontal',
          size: 'normal',
        };
      },
    },
    direction: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    htype: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: String,
      default: '',
    },
  },
  components: {
    Icon: UIIcon,
    Pill,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

@import '@/assets/styles/variables/_buttons.scss';

.Button {
  font-family: $hugr-button-all-font;
  box-sizing: border-box;
  padding: 1rem 1.2rem;
  border: 0;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color .5s ease-in-out 0s,
              color .5s ease-in-out 0s,
              border-radius 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  // padding-right: 8rem;
  text-align: left;
  display: inline-block;
  font-size: 1em;

  border-radius: 3px;

  &_Inner {
    display: flex;
    gap: 6px;
    align-items: center;

    &._justify-center {
      justify-content: center;
    }

    // &_Icon {
    //   float: left;
    //   top: 2px;
    //   position: relative;
    // }

    // &_IconAfter {
    //   float: right;
    //   top: 2px;
    //   position: relative;
    // }

    &_Text {
      white-space: nowrap;
      vertical-align: middle;
    }

    &_Pill {
      margin-left: auto;
    }
  }
  &:focus, &:hover, &.focus, &.hover {
    border-radius: 6px;
  }

  &.primary {
    color: $hugr-button-primary-text;
    background-color: $hugr-button-primary-bg;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-primary-text-focus;
      font-weight: 400;
      background-color: darken($hugr-button-primary-bg, 15%);
    }
    &.disabled {
      background: lighten($hugr-button-primary-bg, 20%);
    }
  }

  &.secondary {
    color: $hugr-button-secondary-text;
    background-color: $hugr-button-secondary-bg;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-secondary-text-focus;
      background-color: darken($hugr-button-secondary-bg, 20%);
    }

    &.disabled {
      background: lighten($hugr-button-secondary-bg, 20%);
    }
  }

  &.tertiary {
    color: $hugr-button-serious-text-focus;
    background-color: $hugr-button-serious-bg-focus;
    &:focus, &:hover, &.focus {
      color: $hugr-button-serious-text-focus;
      background-color: $hugr-button-serious-bg-focus;
    }
  }

  &.serious {
    color: $hugr-button-serious-text;
    background-color: $hugr-button-serious-bg;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-serious-text-focus;
      background-color: darken($hugr-button-serious-bg, 15%);
    }

    &.disabled {
      background-color: $hugr-button-serious-bg;
      opacity: 0.6;
    }
  }

  &.ml {
    color: white;
    background-color: #182433;
  }

  &.green {
    color: $hugr-button-secondary-text;
    background-color: lighten( $hugr-colours-green, 50% );
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-serious-text-focus;
      background-color: lighten( $hugr-colours-green, 30% );
    }
  }
  &.blue {
    color: white;
    background-color: $hugr-colours-tertiary;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-serious-text-focus;
      background-color: lighten( $hugr-colours-tertiary, 20% );
    }
  }

  &.transparent {
    color: $hugr-button-transparent-text;
    background-color: $hugr-button-transparent-bg;
    border: 1px solid $hugr-button-transparent-border;
    &:focus, &:hover, &.focus, &.hover {
      background: $hugr-button-transparent-bg-focus;
      border: 1px solid $hugr-button-primary-bg;
    }
    &._active, &[aria-current]:not([aria-current="false"]) {
      background: $hugr-button-transparent-bg-focus;
    }
  }

  &.border {
    color: $hugr-button-border-text;
    background-color: $hugr-button-border-bg;
    border: 1px solid $hugr-button-border-border;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-border-text-focus;
      background-color: $hugr-button-border-border;
      border: 1px solid $hugr-button-primary-bg;
    }
  }

  &.icon {
    background: transparent;
    width: 35px;
    height: 36px;
    display: inline-block;
    color: $hugr-button-icon-colour;
    overflow: hidden;
    padding: 8px;
    font-size: 1em !important;
    span {
      color: transparent;
    }
    &:focus, &:hover, &.focus, &.hover {
      color: $hugr-button-icon-colour-focus;
    }

    &.size-micro {
      width: 18px;
      height: 18px;
      padding: 2px;
    }
  }

  &.link {
    color: $hugr-colours-new-link;
    text-decoration: underline;
    background: transparent;
    padding: 0;
  }

  &.link-bold {
    color: $hugr-colours-new-link;
    font-weight: bold;
    background: transparent;
    padding: 0;
  }

  &.switch {
    background: transparent;
    // width: 20px;
    // height: 20px;
    display: inline-block;
    padding: 2px;
    color: #262e37;
    background-color: #ffb23e;
    &[aria-checked="true"] {
      color: #ffb23e;
      background-color: #262e37;
    }
  }

  &.icon-switch {
    background: transparent;
    width: 35px;
    height: 36px;
    display: inline-block;
    color: grey;
    overflow: hidden;
    padding: 8px;
    span {
      color: transparent;
    }
    &[aria-checked="true"] {
      color: black;
    }
  }

  &.edit {
    padding: 5px 5px 5px 24px;
    background: transparent;
    color: #5e646a;
    border: 1px solid transparent;

    &:hover, &:focus, &.focus {
      color: #262e37;
      border: 1px solid #5e646a;
    }

    &:before {
      content: "\270E";
      left: 0;
      top: 55%;
    }
  }

  &.variant-expand {
    width: 3.5em;
    height: 3.5em;
    >span {
      color: transparent;
      width: 0;
    }

    &:hover, &:focus, &.focus {
      width: auto !important;
      > span {
        width: auto;
        color: inherit;
      }
    }
  }

  //size and direction
  &.direction-vertical {
    width: 100%;
  }
  &.size-small {
    padding: 8px 10px 12px 10px;
    font-size: 10pt;
    &.border {
      padding: 9px; //to make up for border
    }

    &.variant-expand {
      width: 3em;
      height: 3em;
    }
  }
  &.size-micro {
    padding: 5px;
    font-size: 10pt;
    &.border {
      padding: 4px; //to make up for border
    }
    &:before { //this might not be needed
      content: '';
    }
  }
  &.size-nano {
    font-size: 0.7em;
    padding: 2px 4px;
    &.border {
      padding: 1px 4px; //to make up for border
    }
    &:before { //this might not be needed
      content: '';
    }
  }
}

._darkMode button, ._darkMode a {
  &.primary {
    background-color: $hugr-colours-grey;
    color: $hugr-button-secondary-text;
    &:focus, &:hover, &.focus, &.hover {
      // color: $hugr-button-primary-text-focus;
      font-weight: 400;
      color: white;
    }
  }

  &.border {
    color: $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover {
      background-color: darken($hugr-button-primary-bg, 10%);
      border: 1px solid $hugr-colours-grey;
    }
  }

  &.transparent {
    color: $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover {
      background-color: darken($hugr-button-primary-bg, 10%);
      border: 1px solid $hugr-colours-grey;
    }
  }

  &.serious {
    background-color: darken($hugr-button-serious-bg, 10%);
    border: 1px solid $hugr-colours-grey;
    &:focus, &:hover, &.focus, &.hover {
      background-color: darken($hugr-button-serious-bg, 25%);
    }
  }

  &.icon {
    color: lighten($hugr-button-icon-colour, 40%);
    &:focus, &:hover, &.focus, &.hover {
      color: lighten($hugr-button-icon-colour, 20%);
    }
  }

  &.link {
    color: $dig-orange;
  }

}
</style>
